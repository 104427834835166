import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import NavLogo from "../../assets/logo.png";
import { Loader } from "../minor-components/Loader";
import { useAlert } from "react-alert";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../constants/axiosInstance";
import { baseURL } from "../../constants/baseURL";
import TelegramLogin from "../minor-components/TelegramButton";

export const SignIn = () => {
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
     queryParams.get("token");
  }, [location]);

  const [credentials, setcredentials] = useState({
    userName: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  let alert = useAlert();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { userName, password } = credentials;
  
    try {
      const response = await axiosInstance.post(
        `/api/v1/user/userLogin`,
        { 
          userName: userName.toLowerCase(), // Convert username to lowercase
          password
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200 && response.data.success) {
        const { deactivate } = response.data.data.user;
  
        if (deactivate === 2) {
          alert.show("Your Account has been deactivated. Please Contact Admin");
        } else {
          localStorage.setItem("token", response.data.data.token);
          navigate("/");
          alert.show("Login Successfully");
        }
      } else {
        alert.show("Something is wrong, confirm your credentials or contact admin.");
      }
    } catch (error) {
      console.error("Login error:", error);
      alert.show("Something is wrong, confirm your credentials or contact admin.");
    } finally {
      setLoading(false);
    }
  };
  

  const onChange = (e) => {
    setcredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <>
      {!loading ? (
        <div className="h-[calc(100vh_-_5rem)] flex bg-emerald-50 items-center justify-center py-20 px-4 sm:px-6 lg:px-8">
          <div className="w-full lg:w-4/12  md:w-6/12 shadow-md rounded-md bg-white flex justify-center">
            <div  className="h-auto p-8 w-full">
              <div className="w-full">
                <div className="w-full">
                  <img
                    className="mx-auto w-[85px]"
                    src={NavLogo}
                    alt="Workflow"
                  />
                  <h2 className="mt-2 text-center text-2xl font-normal text-gray-600">
                    Sign Into your Account
                  </h2>
                </div>
                <form className="mt-8" onSubmit={handleSubmit}>
                  <div className="rounded-md shadow-sm">
                    <div>
                      <div className="mb-2 mt-2 ">
                        <label className="font-bold">Username</label>
                      </div>
                      <input
                        id="userName"
                        name="userName"
                        type="userName"
                        autoComplete="userName"
                        value={credentials.userName}
                        onChange={onChange}
                        required
                        className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-myBg-500 focus:border-myBg-500 focus:z-10 sm:text-sm"
                        placeholder="Username"
                      />
                    </div>
                    <div>
                      <div className="mb-2 mt-2 ">
                        <label className="font-bold">Password</label>
                      </div>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        value={credentials.password}
                        onChange={onChange}
                        required
                        className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-myBg-500 focus:border-myBg-500 focus:z-10 sm:text-sm"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-gray-900"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="text-sm my-4">
                      <Link
                        to="/reset-password"
                        className="font-medium text-sky-600 hover:text-myBg"
                      >
                        {" "}
                        Forgot Password?{" "}
                      </Link>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-secondaryText bg-myBg hover:bg-myBg-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-myBg-500"
                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                      Login
                    </button>
                   {/* <TelegramLogin botName="Buildingbloquesbot" authUrl="https://buildingbloques.com"/> */}
                  </div>
                  <div className="flex w-full my-4 items-center justify-start">
                    <div className="font-medium ">
                      Don't have an account? Request for signup link.
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

// export default SignIn;
