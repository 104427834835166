import { SlickSlider } from "../minor-components/SlickSlider";
import { Card } from "../minor-components/Card";
import { baseURL } from "../../constants/baseURL";

export const SinglDashboardProduct = (props) => {
  console.log(props);
  return (
    <>
      {props.istype === "categories" ? (
        <>
          <SlickSlider className="flex">
            {props.products?.map((item, index) => (
              <div key={index} className="px-4">
                <Card
                  productPhoto={item.categoryPhoto}
                  title={item.brand}
                  value={index}
                  type="category"
                />
              </div>
            ))}
          </SlickSlider>
        </>
      ) : (
        <div className="flex">
          {props.products?.map((item, index) => (
            <>
              {item.brand === props.brand ? (
                <div key={index} className="px-4">
                  <Card
                    svg={item.productPhoto}
                    title={item.name}
                    desc={item.description}
                    price={item.price}
                    item={item}
                    value={index}
                    getItem={props.handleItem}
                  />
                </div>
              ) : null}
            </>
          ))}
        </div>
      )}
    </>
  );
};
