import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckoutProducts } from "../minor-components/CheckoutProducts";
import { CheckoutUserDetails } from "../minor-components/CheckoutUserDetails";
import { axiosInstance } from "../../constants/axiosInstance";
import { Loader } from "../minor-components/Loader";
import { useAlert } from "react-alert";
import { Footer } from "./Footer";
import { useAuth } from "../../authProvider";

export const Checkout = () => {
  const [loading, setLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [cartUser, setCartUser] = useState([]);
  const [subTotalPrice, setSubTotalPrice] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [totalProducts, setTotalProducts] = useState([]);
  const [deliveryFee, setDeliveryFee] = useState(5);
  const [order, setOrder] = useState({});
  const [invoiceUrl, setInvoiceUrl] = useState("");
  let navigate = useNavigate();
  const alert = useAlert();
  const { setCartCount, cartCount } = useAuth();

  useEffect(() => {
    window.btcpay.onModalReceiveMessage(function (event) {
      event.preventDefault();
      if (event.data.status === "complete") {
        handleOrderPlacement();
      }
    });
  }, [order]);

  const fetchCart = async () => {
    setLoading(true);
    await axiosInstance
      .get("/api/v1/order/getcart", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.data.data !== null) {
          setLoading(false);
          setCartItems(response.data.data?.details);
          setSubTotalPrice(response.data.data.subTotal);
          setTotalProducts(response.data.data.totalProducts);
          setTotalPrice(response.data.data.totalPrice);
          setCartUser(response.data.data?.userId);
          setCartCount(response.data.data.totalProducts);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchCart();
      setLoading(false);
      navigate("/checkout");
    } else {
      navigate("/login");
    }
  }, [loading]);

  const handleDelivery = async (day) => {};

  const handleSubmit = async (
    deliveryDay,
    total,
    deliveryFee,
    address,
    postalCode,
    paymentMethod,
    coordinates
  ) => {
    // Validate payment method selection
    if (paymentMethod === "") {
      alert.show("Please Select Payment Method");
      return;
    }
    // Create the order object
    const order = {
      details: cartItems,
      totalPrice: total,
      deliveryFee,
      deliveryType: deliveryDay,
      subTotal: total - deliveryFee,
      totalProducts: totalProducts,
      formattedAddress: address,
      postalCode: postalCode,
      paymentMethod: paymentMethod,
      geometry: [coordinates[0], coordinates[1]],
      user: cartUser,
    };
    // Start processing
    setLoading(true);
    const user = {
      userName: cartUser.userName,
      chatId: cartUser.chatId,
      _id: cartUser._id,
    }
    try {
      if (paymentMethod === "Cryptocurrency") {
        setOrder(order);
        handleCryptoPayment(total, user, order);
      } else {
        const response = await axiosInstance.post(
          "/api/v1/order/placeorder",
          order,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        if (response.data.success) {
          // If order is successfully placed, proceed to clear the cart

          // fetchCart();
          const cartResponse = await axiosInstance.delete(
            "/api/v1/order/deletecart",
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          );

          if (cartResponse.data.success) {
            alert.show("Order Placed Successfully");
            setCartCount(0);
            navigate("/order/" + response.data.data);
          } else {
            alert.show("Something Went Wrong with clearing the cart");
          }
        } else {
          alert.show(response.data.message || "Failed to place the order");
        }
      }
    } catch (error) {
      console.error("Error during order placement:", error);
      alert.show("An error occurred while placing the order.");
    } finally {
      setLoading(false);
    }
  };

  const handleCryptoPayment = async (totalPrice, user, order) => {
    try {
      const response = await axiosInstance.post(
        `/api/v1/order/createinvoice`,
        {
          totalPrice: totalPrice,
          currency: "EUR",
          user: user,
          order: order,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.data;
      if (data.success) {
        setInvoiceUrl(data.data.url);

        const invoiceId = data.data.id;
        window.btcpay.showInvoice(invoiceId);
      } else {
        console.error("Failed to create payment invoice:", data.message);
      }
    } catch (error) {
      console.error("Error in payment API:", error);
    }
  };

  const handleOrderPlacement = async () => {
    try {
      if (!order || !order.details.length) {
        console.log("No order data available to place the order");
        return;
      }
      const response = await axiosInstance.post(
        "/api/v1/order/placeorder",
        order,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      if (response.data.success) {
        const cartResponse = await axiosInstance.delete(
          "/api/v1/order/deletecart",
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        if (cartResponse.data.success) {
          alert.show("Order Placed Successfully");
          setCartItems([]);
          setTotalPrice(0);
          setSubTotalPrice(0);
          setTotalProducts(0);
          navigate(`/order/${response.data.data}`);
        } else {
          alert.show("Something Went Wrong with clearing the cart");
        }
      } else {
        alert.show(response.data.message || "Failed to place the order");
      }
    } catch (error) {
      console.error("Error placing order:", error);
      alert.show("An error occurred while placing the order.");
    } finally {
      setLoading(false);
    }
  };

  const handleItem = async (item, action) => {
    setLoading(true);
    const details = {
      productId: item.id,
      quantity: item.quantity,
      price: item.price,
    };
    let url = "";

    if (action === "p") {
      url = "/api/v1/order/addtocart";
    } else if (action === "m") {
      url = "/api/v1/order/decreasecartquantity";
    }

    axiosInstance
      .post(url, details, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setLoading(false);
        if (action === "m") {
          window.location.reload();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      {!loading ? (
        <>
          <div className="bg-neutral-50 min-h-[calc[(100vh_-_5rem)]">
            {cartItems.length !== 0 || !cartItems ? (
              <>
                <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32 ">
                  <div className="px-4 pt-8">
                    <p className="text-xl font-medium ">Order Summary</p>
                    {cartItems.map((product, index) => {
                      return (
                        <div
                          className="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6 "
                          key={index}
                        >
                          <CheckoutProducts
                            cartProductId={product?.productId?._id}
                            cartProductName={product?.productId?.name}
                            cartProductImage={product?.productId?.productPhoto}
                            cartProductPrice={product?.productId?.price}
                            cartProductBrand={product?.productId?.brand}
                            cartProductCategory={product?.productId?.category}
                            cartProductDescription={
                              product?.productId?.description
                            }
                            cartProductType={product?.productId?.type}
                            cartProductQuantity={product?.quantity}
                            getItem={handleItem}
                            fetchCart={fetchCart}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-10  px-4 pt-8 lg:mt-0">
                    <CheckoutUserDetails
                      handleSubmit={handleSubmit}
                      handleDeliveryDay={handleDelivery}
                      cartUser={cartUser}
                      totalPrice={totalPrice}
                      subTotalPrice={subTotalPrice}
                      deliveryFee={deliveryFee}
                      setDeliveryFee={setDeliveryFee}
                      handleOrderPlacement={handleOrderPlacement}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="block text-center justify-center my-10 min-h-[calc(100vh_-_11.5rem)]">
                  <lottie-player
                    src="https://assets1.lottiefiles.com/packages/lf20_qh5z2fdq.json"
                    background="transparent"
                    speed={1}
                    style={{ height: "300px", display: "inline-block" }}
                    loop
                    autoPlay
                  />
                  <p className="text-4xl text-black font-bold">
                    Cart is Empty! Please Add some Products
                  </p>
                </div>
              </>
            )}
          </div>
          <div>
            <Footer />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
