import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Noty from "noty";
import { axiosInstance } from "../../constants/axiosInstance";
import { Footer } from "./Footer";
import { useAlert } from "react-alert";

export const Account = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const [enable, setEnable] = useState(true);
  const [credentials, setcredentials] = useState({
    userName: "",
    chatId: "",
    formattedAddress: "",
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchUser();
      navigate("/accounts");
    } else {
      navigate("/login");
    }
  }, []);

  const fetchUser = async () => {
    await axiosInstance
      .get("/api/v1/user/getsingleuser", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setcredentials({
          userName: response.data.data.userName,
          chatId: response.data.data.chatId,
          formattedAddress: response.data.data.formattedAddress,
        });
      })
      .catch((err) => {
        new Noty({
          type: "error",
          timeout: 2000,
          text: "Something Went Wrong",
        }).show();
      });
  };

  const handleEdit = async () => {
    setEnable(!enable);
  };

  const handleUpdate = async () => {
    const { userName, chatId, formattedAddress } = credentials;

    await axiosInstance
      .patch(
        "/api/v1/user/updateuser",
        { userName, chatId, formattedAddress },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setcredentials({
          userName: response.data.data.userName,
          chatId: response.data.data.chatId,
        });
        new Noty({
          type: "success",
          timeout: 2000,
          text: "Successfully updated",
        }).show();
        setEnable(true);
      })
      .catch((err) => {
        new Noty({
          type: "error",
          timeout: 2000,
          text: "Something Went Wrong",
        }).show();
      });
  };

  const onChange = (e) => {
    setcredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleRequestDelete = async () => {
    const chatId = credentials.chatId;
    const name = credentials.userName;
    await axiosInstance
      .patch(
        "/api/v1/user/disableaccount",
        { chatId, name },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        alert.show("Requeat send to Admin");
      })
      .catch((err) => {
        alert.show("Something Went Wrong");
      });
  };

  return (
    <>
      <div className="h-[calc(100vh_-_8.5rem)] flex bg-emerald-50 items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 lg:m-8 md:m-2 sm:m-0  text-[#4E4E4E]">
          <div className="w-12/12">
            <div className="w-12/12 p-7 lg:mx-4 md:mx-0 sm:mx-0  shadow-md rounded bg-white flex justify-center">
              <div className="h-auto w-full ">
                <div className="flex justify-between">
                  <div className="md:w-full font-semibold">Basic Information</div>
                  <div
                    className="text-[#8275FC] md:w-full cursor-pointer font-semibold"
                    onClick={handleEdit}
                  >
                    Edit Information
                  </div>
                </div>
                <div className="w-full grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
                  <div className="w-full  mt-4 lg:my-8 md:my-4">
                    <div className="w-12/12">
                      <div className="mb-4">
                        <label>Telegram Username</label>
                      </div>
                      <input
                        name="userName"
                        type="text"
                        required
                        disabled={enable}
                        onChange={onChange}
                        value={credentials.userName}
                        className={
                          enable === true
                            ? "bg-[#D3D3D3] appearance-none w-11/12 rounded px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-myBg-500 focus:border-myBg-500 focus:z-10 sm:text-sm"
                            : "bg-white appearance-none w-11/12 rounded px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-myBg-500 focus:border-myBg-500 focus:z-10 sm:text-sm"
                        }
                        placeholder="User Name"
                      />
                    </div>
                  </div>
                  <div className="w-full mt-4 lg:my-8 md:my-4">
                    <div className="w-12/12">
                      <div className="mb-4">
                        <label>Telegram ChatId</label>
                      </div>
                      <input
                        name="chatId"
                        type="number"
                        required
                        disabled={enable}
                        onChange={onChange}
                        value={credentials.chatId}
                        className={
                          enable === true
                            ? "bg-[#D3D3D3] appearance-none w-11/12 rounded px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-myBg-500 focus:border-myBg-500 focus:z-10 sm:text-sm"
                            : "bg-white appearance-none w-11/12 rounded px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-myBg-500 focus:border-myBg-500 focus:z-10 sm:text-sm"
                        }
                        placeholder="ChatId"
                      />
                    </div>
                  </div>
                  <div className="w-full mt-4 lg:mt-0">
                    <div className="w-12/12">
                      <div className="mb-4">
                        <label>Shipping Address</label>
                      </div>
                      <input
                        name="formattedAddress"
                        type="text"
                        required
                        disabled={enable}
                        onChange={onChange}
                        value={credentials.formattedAddress}
                        className={
                          enable === true
                            ? "bg-[#D3D3D3] appearance-none w-11/12 rounded px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-myBg-500 focus:border-myBg-500 focus:z-10 sm:text-sm"
                            : "bg-white appearance-none w-11/12 rounded px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-myBg-500 focus:border-myBg-500 focus:z-10 sm:text-sm"
                        }
                        placeholder="Address"
                      />
                    </div>
                  </div>
                </div>

                  <div
                    className="w-full flex text-[#4E4E4E] my-8"
                    onClick={handleUpdate}
                  >
                    <button className="w-[95%] rounded px-3 py-3 bg-[#E9C95D] cursor-pointer">
                      Save Changes
                    </button>
                  </div>
              </div>
            </div>
          </div>
          <div className="w-12/12 lg:mt-0 md:mt-4 sm:mt-4">
            <div className="mb-6 w-11/12">
              <h1 className="text-2xl font-semibold">Delete My Account</h1>
              <p className="text-md mt-4 text-[#A2A2A2]">
                Request to delete your personal information from Eaze by
                clicking the button below. We'll send an chatId to{" "}
                {credentials?.chatId} to verify your request. Please review our
                Privacy Policy for more information about deleting your data
                before submitting your request.
              </p>
            </div>
            <div
              className="w-8/12 flex text-[#4E4E4E] my-8"
              onClick={handleRequestDelete}
            >
              <button className="w-full rounded px-4 py-4 bg-[#E9C95D]">
                Request Deletion
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};
