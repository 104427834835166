import { useState , useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { axiosInstance } from "../../constants/axiosInstance";
import { useAlert } from 'react-alert'
import NavLogo from "../../assets/logo.png";

export const ConformPassword = () => {

  const params = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const [isToken , setIsToken] = useState(false)
  const [Password , setPassword] = useState({
    password : '',
    cpassword : ''
  })
  const [Id , setId] = useState({
    id : '',
  })

  const token = params.token ;

  useEffect(() => {
    verifyToken();

  }, []);


  const verifyToken = async () => {
    await axiosInstance.patch('/api/v1/user/verifyjwttoken' , {token} )
          .then((res) => {
            setIsToken(true)
            setId({ id :  res.data.data})
          })
          .catch((err) => {
            setIsToken(false)
          })
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, cpassword } = Password;
  
    if (password.length < 6) {
      alert.show("Password must be at least 6 characters long.");
      return; // Prevent the form from being submitted
    }
  
    if (password !== cpassword) {
      alert.show("Passwords do not match.");
      return; // Prevent the form from being submitted
    }
  
    try {
      const response = await axiosInstance.patch('/api/v1/user/updatepassword', {
        token: params.token, // Use the token from the URL parameters
        newPassword: password // The new password from the form
      });
      
      alert.show(response.data.message);
      
      navigateToLogin("login");
    } catch (err) {
      alert.show("Something went wrong. Please try again.");
    }
  };  

  const navigateToLogin = (purpose) => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert.show("No authentication token found. Please log in.");
      return;
    }

    axiosInstance
      .post(
        "/api/v1/tokens/generate",
        { purpose },
        { headers: { Authorization: token } }
      )
      .then((response) => {
        const newToken = response.data.token;
        navigate(`/${purpose}?token=${newToken}`);
      })
      .catch((error) => {
        console.error("Error generating token:", error);
        alert.show("Failed to generate link. Please try again.");
      });
  };
  
  const onChange = (e) => {
    setPassword({ ...Password, [e.target.name]: e.target.value });
  };

  return (
    <>
    {isToken ? 
      <div className="h-[100vh] flex bg-emerald-50 items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-auto shadow-md rounded-md bg-white flex justify-center">
        <div floated={false} className="h-auto p-12">
          <div className="max-w-md w-full space-y-8">
            <div className="w-96">
              <img
                className="mx-auto w-[85px]"
                src={NavLogo}
                alt="Workflow"
              />
              <h2 className="mt-2 pb-7 text-center text-xl font-normal text-gray-600">
                Reset Your Password
                <p className="text-sm font-medium text-gray-400">Type here your new Password.</p>
              </h2>
              
            </div>
            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <div className="mb-2 ml-1 ">
                    <label className="font-bold">Password</label>
                  </div>
                  <input
                    id="password"
                    type="password"
                    onChange={onChange}
                    name="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none rounded relative block w-96 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-myBg-500 focus:border-myBg-500 focus:z-10 sm:text-sm"
                    placeholder="Password"
                  />
                </div>
              </div>
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <div className="mb-2 ml-1 ">
                    <label className="font-bold">Confirm Password</label>
                  </div>
                  <input
                    id="password"
                    type="password"
                    onChange={onChange}
                    placeholder="Confirm password"
                    name="cpassword"
                    autoComplete="current-password"
                    required
                    className="appearance-none rounded relative block w-96 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-myBg-500 focus:border-myBg-500 focus:z-10 sm:text-sm"
                  />
                </div>
              </div>

              <div className="pt-3">
                <button
                  type="submit"
                  className="group relative w-96 flex justify-center  py-2 px-4 border border-transparent text-sm font-medium rounded-md text-secondaryText bg-myBg hover:bg-myBg-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-myBg-500"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                  Reset Password
                </button>
              </div>
            </form>


          </div>
        </div>
      </div>
    </div>
    :
      <h1 className="text-center m-4">Please Try Again, Session Expired</h1>
    }
    
    </>
  );
};

