/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { axiosInstance } from "../../constants/axiosInstance";
import { baseURL } from "../../constants/baseURL";

import { Loader } from "./Loader";

import { useAuth } from "../../authProvider";

export const SingleProductDetails = ({ item }) => {
  const { setCartCount } = useAuth();
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(item?.price || 0);
  const alert = useAlert();
  const navigate = useNavigate();

  const addToCart = async () => {
    if (!localStorage.getItem("token")) {
      alert.show("Please sign in to add items to your cart.");
      navigate("/");
      return;
    }

    const details = {
      productId: item.id,
      name: item.name,
      price: item.price,
      brand: item.brand,
      productPhoto: item.productPhoto,
      type: item.type,
      description: item.description,
      category: item.category,
      quantity: 1,
    };
    try {
      const response = await axiosInstance.post(
        "/api/v1/order/addtocart",
        details,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      setCartCount(response.data.data.totalProducts);
      alert.show("Product Added to Cart");
      // window.location.reload();
    } catch (err) {
      alert.show("Failed to add product to cart. Please try again.");
    }
  };

  const imageURL = item.productPhoto.startsWith(baseURL)
    ? item.productPhoto
    : `${item.productPhoto}`;

  if (loading) return <Loader />;

  return (
    <div className="w-full h-auto flex text-[#4A4A4A] bg-neutral-50 mt-4">
      <div className="w-full grid grid-cols-1 overflow-x-auto">
        <div className="p-6">
          <img
            src={imageURL}
            alt="Product"
            className="rounded-t-lg w-full h-60 object-cover object-center bg-slate-200"
          />
          <div className="shadow rounded-b-lg p-4 bg-white">
            <h1 className="text-xl font-bold">{item.name || "Product Name"}</h1>
            <p className="text-sm">Category: {item.type || "Product Type"}</p>
            <p className="text-sm text-gray-500 ">
              Description: {item.description || "No description provided."}
            </p>
            <p className="text-sm font-semibold">Price: ${price}</p>
          </div>
          <button
            className="mt-4 w-full bg-[#E9C95D] text-black p-2 rounded hover:bg-[#D9BA5C] transition"
            onClick={addToCart}
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};
