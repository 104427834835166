/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { getLatLng } from "use-places-autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderImage from "../../assets/hero-section-img.png";
import { baseURL } from "../../constants/baseURL";
import { useNavigate } from "react-router-dom";

const apiKey = process.env.REACT_APP_GMAP_API_KEY;
const mapApiJs = process.env.REACT_APP_MAP_API_JS;
const geocodeJson = process.env.REACT_APP_GEOCODE_JSON;



let lats, lngs;
let formatted_address;

function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

const extractAddress = (place) => {
  // const results = await getGeocode({ place });

  if (
    typeof place.geometry.location.lat &&
    typeof place.geometry.location.lng != "function"
  ) {
    lats = place.geometry.location.lat;
    lngs = place.geometry.location.lng;
  } else {
    const { lat, lng } = getLatLng(place);
    lats = lat;
    lngs = lng;
  }

  formatted_address = place.formatted_address;

  const address = {
    sublocal2: "",
    sublocal: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    plain() {
      const sublocal2 = this.sublocal2 ? this.sublocal2 + ", " : "";
      const sublocal = this.sublocal ? this.sublocal + ", " : "";
      const city = this.city ? this.city + ", " : "";
      const zip = this.zip ? this.zip + ", " : "";
      const state = this.state ? this.state + ", " : "";
      return sublocal2 + sublocal + city + zip + state + this.country;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes("sublocality_level_2")) {
      address.sublocal2 = value;
    }
    if (types.includes("sublocality_level_1")) {
      address.sublocal = value;
    }
    if (types.includes("locality")) {
      address.city = value;
    }

    if (types.includes("administrative_area_level_2")) {
      address.state = value;
    }

    if (types.includes("postal_code")) {
      address.zip = value;
    }

    if (types.includes("country")) {
      address.country = value;
    }
  });

  return address;
};

export const HeroMapInput = (props) => {
  const searchInput = useRef(null);
  const [address, setAddress] = useState({});
  const navigate = useNavigate();

  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  // do something on address change
  const onChangeAddress = async (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;
    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setFields([
      "address_component",
      "formatted_address",
      "geometry",
    ]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  const reverseGeocode = async ({ latitude: lat, longitude: lng }) => {
    const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
    searchInput.current.value = "Getting your location...";
    fetch(url)
      .then((response) => response.json())
      .then((location) => {
        const place = location.results[0];
        const _address = extractAddress(place);
        setAddress(_address);
        searchInput.current.value = _address.plain();
      });
  };

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        reverseGeocode(position.coords);
      });
    }
  };

  // load map script after mounted
  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = {
      geometry: { coordinates: [lats, lngs] },
      formattedAddress: formatted_address,
    };
    props.gromatryLocation(obj);
    navigate('/all-products')
  };
  
  return (
    <div
      style={{
        backgroundImage: `url(${
          props.headerImage !== "" ? baseURL + props.headerImage : HeaderImage
        })`,
      }}
      className={`w-[100%] h-screen bg-center bg-cover bg-fixed bg-sm-auto bg-md-auto bg-lg-cover bg-xl-cover flex flex-col justify-center items-center ${
        props.located === true ? "opacity-75" : null
      }`}
    >
      <h1 className="text-4xl text-secondaryText font-bold">
        Cannabis Delivery
      </h1>

      <form onSubmit={handleSubmit}>
        <div className="flex flex-wrap items-center justify-center gap-3 my-4">
          <button className="px-4 text-lg w-44 h-12 text-primaryText font-semibold bg-myBg rounded text-center">
            Shop Now
          </button>
        </div>
        {props.located ? (
          <p className="text-md text-red-600 font-bold text-center">
            Sorry, service is not available in this Area!
          </p>
        ) : null}
      </form>
    </div>
  );
};
