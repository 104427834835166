/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { axiosInstance } from "../../constants/axiosInstance";
import { useAlert } from "react-alert";

export const CheckoutProducts = ({ fetchCart, ...product }) => {
  const [status, setStatus] = useState(false);

  let num = 0;
  const alert = useAlert();
  // let total = 0;

  const handleCart = async (e, action) => {
    if (action === true) {
      setStatus(false);
    } else {
      setStatus(true);
    }
  };

  const handleCartProducts = async (e, action) => {
    let quantities = document.querySelectorAll(
      `[data-value="${e.target.dataset.keyvalue}"]`
    );
    quantities.forEach((quantity, index) => {
      if (action === "plus") {
        num = parseInt(quantity.value);
        num += 1;
        quantity.value = num;
        console.log("plus", num);
        let obj = {
          id: product.cartProductId,
          quantity: 1,
          price: product.cartProductPrice,
        };
        product.getItem(obj, "p");
      } else if (action === "minus") {
        num = parseInt(quantity.value);
        num = num - 1;
        quantity.value = num;
        let obj = {
          id: product.cartProductId,
          quantity: num,
          price: product.cartProductPrice,
        };
        product.getItem(obj, "m");
      }
    });
  };

  const handleDeleteProductFromCart = async (productId) => {
    try {
      const response = await axiosInstance.post(
        "/api/v1/order/deleteCartProductById",
        {
          productId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      alert.show(response.data.message);
      fetchCart();

      window.location.reload();
    } catch (error) {
      alert.show(error.message);
    }
  };

  return (
    <>
      {product.cartProductQuantity !== 0 ? (
        <>
          <div className="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6 ">
            <div className="flex flex-col rounded-lg bg-white sm:flex-row relative">
              <img
                className="m-2 h-24 w-28 rounded-md border object-cover object-center"
                src={`${product.cartProductImage}`}
                alt=""
              />
              {product.cartProductQuantity !== 0 && (
                <>
                  <div className="flex w-full flex-col px-4 py-4">
                    <span className="font-semibold">
                      {product.cartProductName} - {product.cartProductCategory}
                    </span>
                    <span className="float-right text-gray-400">
                    €{product.cartProductPrice} x{" "}
                      {product.cartProductQuantity}
                    </span>
                    <p className="text-lg font-bold">
                    €{product.cartProductPrice * product.cartProductQuantity}
                    </p>
                  </div>
                  <div className="flex w-full flex-col px-4 py-4 ">
                    <div className="custom-number-input m-[4%]  w-28 absolute top-0 right-0">
                      <div className="flex flex-row h-6 w-full border border-[#FFFFFF]-600 rounded-lg relative opacity-[0.67] bg-[#000000] hover:text-[#000000]-700 hover:bg-[#000000]-400 bg-transparent mt-1">
                        <span
                          onClick={
                            product.cartProductQuantity !== 0
                              ? (e) => handleCartProducts(e, "minus")
                              : (e) => handleCart
                          }
                          data-keyvalue={product.cartProductQuantity}
                          className={`flex items-center w-full justify-center border-[#FFFFFF]-600 opacity-[0.67] bg-[#000000]  text-white rounded-l-[20%]  h-full   ${
                            product.cartProductQuantity !== 0
                              ? "cursor-pointer"
                              : "cursor-not-allowed "
                          } outline-none m-auto text-lg font-thin`}
                        >
                          -
                        </span>
                        <input
                          type="text"
                          data-value={product.cartProductQuantity}
                          id="quantity"
                          readOnly
                          className="quantity focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-gray-600 focus:text-gray-600  md:text-basecursor-default flex items-center text-gray-700 outline-none"
                          name="custom-input-number"
                          value={product.cartProductQuantity}
                        />
                        <span
                          onClick={(e) => handleCartProducts(e, "plus")}
                          data-keyvalue={product.cartProductQuantity}
                          className=" flex items-center w-full border-[#FFFFFF]-600  opacity-[0.67] rounded-r-[20%] justify-center bg-[#000000] text-white text-lg hover:text-[#000000]-700 hover:bg-[#000000]-400 h-full  cursor-pointer m-auto  font-thin"
                        >
                          +
                        </span>
                      </div>
                      <div className="mt-5 text-right">
                        <button
                          className="text-[rgb(239,68,68)]"
                          onClick={() =>
                            handleDeleteProductFromCart(product.cartProductId)
                          }
                        >
                          <FontAwesomeIcon icon="fa-solid fa-trash" />
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div></div>
          </div>
        </>
      ) : null}
    </>
  );
};
