import { SinglDashboardProduct } from './SinglDashboardProduct';

export const DashboardProducts = (props) => {

    return (
        <>
            <div className='flex'>
                    {props.istype === 'categories' ?
                    
                        <SinglDashboardProduct products={props.products} istype='categories'/>
                    :
                        <SinglDashboardProduct products={props.products} istype='brand' brand={props.brand} handleItem={props.handleItem} />
                    }
                
            </div>
        </>
    )
}